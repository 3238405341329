/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { rhythm } from '../utils/typography';

const Bio = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            linkedIn
            github
            email
          }
        }
      }
    }
  `);

  const { author, social } = data.site.siteMetadata;
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(1 / 2),
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Image
        className="rythm-pulse"
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 20,
          minWidth: 50,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <div style={{ textAlign: 'center' }}>
        <p>
          Written by <strong>{author.name}</strong>
          <br />
          {author.summary}
          <br />
          <a href={social.linkedIn} target="blank" rel="noreferrer noopener">
            LinkedIn
          </a>
          {` · `}
          <a href={social.github} target="blank" rel="noreferrer noopener">
            GitHub
          </a>
          {` · `}
          <a href={social.email} target="blank" rel="noreferrer noopener">
            Email
          </a>
        </p>
      </div>
    </div>
  );
};

export default Bio;
